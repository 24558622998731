/* eslint-disable max-lines-per-function */
import { defineStore, storeToRefs } from "pinia";
import { ref, computed,onMounted,watch } from "vue";
import { useBuilderStore } from "./builder";
import { useBookings } from "./bookings";
import type { checklistPrimaryNode } from '../components/Navbar/checklist-types';
import { BuilderRecord, Builders, type DataInstance } from "@busy-human/hxp-library";


export const useRegistrationStore = defineStore('registration', () => {
    type userStatus = {
        id: string,
        status: string
    }

    interface BuilderDepositStatus {
        builderId: string;
        depositAmountDue: number;
        isDepositPaid: boolean;
        paymentPending: boolean;
        selected: boolean;
    }

    const checklistContent: checklistPrimaryNode[] = [
        {description: "First Experience", pathName: "/first-experience"},
        {description: "Parent/Guardian Info", pathName: "/parent-info"},
        {description: "Contact Information", pathName: "/builder-info"},
        {description: "Trip Preference", pathName: "/preferences"},
        {description: "Policies", pathName: "/policies", subList: [
            {description: "Deal Breakers", value: "dealBreakers"},
            {description: "Refund Policy", value: "refundPolicy"},
            {description: "Contingency Plan", value: "contingencyPlan"},
            {description: "Vaccine Agreement", value: "vaccines"},
            {description: "Builder Agreement", value: "builderAgreement"},
        ]},
        {description: "Payment", pathName: "/payment"}
    ];


    const builderPaymentStatuses = ref<BuilderDepositStatus[]>([]);

    const availableSteps = ref(<number[]>[]);

    function setBuilderPaymentStatus(builderStatuses: BuilderDepositStatus[]){
        builderPaymentStatuses.value = [];
        builderPaymentStatuses.value = [...builderStatuses];
        console.log("Builder paymentStatuses", builderPaymentStatuses.value);
    }

    function getBuilderPaymentStatus(builderId: string):BuilderDepositStatus | undefined {
        return builderPaymentStatuses.value.find(status => status.builderId === builderId);
    }

    function getBuilderCompletedSteps(builderRecord: BuilderRecord.Model){
        const steps: string[] = [];

        if(!builderRecord) return steps;

        if(builderRecord.firstExperience){
            steps.push("First Experience");
        }

        if(builderRecord.guardianStatus === "complete"){
            steps.push("Parent/Guardian Info");
        }

        if(builderRecord.contactInfoStatus === "complete"){
            steps.push("Contact Information");
        }

        if(builderRecord.prefStatus === "complete"){
            steps.push("Trip Preference");
        }

        if(builderRecord.policyStatus === "complete"){
            steps.push("Policies");
        }
        if(builderRecord.policies){

            if(builderRecord.policies.dealBreakers.status === "complete"){
                steps.push("Deal Breakers");
            }

            if(builderRecord.policies.refundPolicy.status === "complete"){
                steps.push("Refund Policy");
            }

            if(builderRecord.policies.contingencyPlan.status === "complete"){
                steps.push("Contingency Plan");
            }

            if(builderRecord.policies.vaccines.status === "complete"){
                steps.push("Vaccine Agreement");
            }

            if(builderRecord.policies.builderAgreement.status === "complete"){
                steps.push("Builder Agreement");
            }

        }

        if(builderRecord.depositPending){
            steps.push("Deposit Submitted");

        } else if(builderRecord.depositPaid){
            steps.push("Deposit Submitted");
            steps.push("Deposit Successful");
        }
        return steps;
    };


    const allPoliciesCompleted = computed(()=>{
        const builderStore = useBuilderStore();
        const builderRecord = builderStore.currentBuilderRecord;
        if(builderRecord){
            if(builderRecord.policies.dealBreakers.status === "complete" &&
                builderRecord.policies.refundPolicy.status === "complete" &&
                builderRecord.policies.contingencyPlan.status === "complete" &&
                builderRecord.policies.vaccines.status === "complete" &&
                builderRecord.policies.builderAgreement.status === "complete") {
                    return true;
            }
        }

        return false;
    });

    const completedSteps = computed(() => {
        const builderStore = useBuilderStore();
        const steps: string[] = [];
        if(builderStore.currentBuilderRecord){
            const selectedRecord = builderStore.currentBuilderRecord;
            steps.push(...getBuilderCompletedSteps(selectedRecord));
        }
        return steps;
    });

    watch(()=> completedSteps.value, completed=>{
        availableSteps.value = [];
        availableSteps.value.push(0);
        if(completed.includes("Parent/Guardian Info")){
            availableSteps.value.push(1);
        }

        if(completed.includes("Parent/Guardian Info")){
            availableSteps.value.push(2);
        }

        if(completed.includes("Contact Information")){
            availableSteps.value.push(3);
        }

        if(completed.includes("Trip Preference")){
            availableSteps.value.push(4);
        }

        if(completed.includes("Policies")){
            availableSteps.value.push(5);
        }
    },{immediate:true, deep:true});


    const getContactInfoStatus = computed(() =>{
        const builderStore = useBuilderStore();
        const statusArray: userStatus[] = [];
        builderStore.asArray.forEach((builder)=>{
            const tmpBuilder = {
                id: builder.$id,
                status: builder.contactInfo
            };
            statusArray.push(tmpBuilder);
        });
        return statusArray;
    });

    const getStatus = computed(() =>{
        const builderStore = useBuilderStore();
        const statusArray: userStatus[] = [];
        builderStore.asArray.forEach((builder)=>{
            const tmpBuilder = {
                id: builder.$id,
                status: builder.contactInfo
            };
            statusArray.push(tmpBuilder);
        });
        return statusArray;
    });


    function groupCompletedStep(stepName: string){
        const builderStore = useBuilderStore();
        let buildersCompleted = 0;
        let builderListLen = 0;
        builderStore.asArray.forEach((builder)=>{
            if(builder.builderType !== 'atHomeParent' || stepName === 'Contact Information'){
                builderListLen += 1;
            }
        });

        if(stepName === 'Contact Information'){
            builderStore.recordsAsArray.forEach((record) =>{
                const builderSteps = [... getBuilderCompletedSteps(record as BuilderRecord.Model)];
                //console.log("Builder Steps:", builderSteps);
                if(builderSteps.includes(stepName)){
                    buildersCompleted += 1;
                }
            });
        }else if(stepName === 'Payment'){
            const bookings = useBookings();
            if(bookings.isReady && bookings.allDepositBookingsPaid){
                buildersCompleted = builderListLen;
            }else if(!bookings.allDepositBookingsPaid){
                let buildersDoneWithPolicies = 0;
                builderStore.recordsAsArray.forEach((record)=>{
                    const builderSteps = [... getBuilderCompletedSteps(record as BuilderRecord.Model)];
                    if(builderSteps.includes('Policies')){
                        buildersDoneWithPolicies += 1;
                    }
                });

                if(buildersDoneWithPolicies === builderListLen){
                    return 'in-progress';
                }
            }
        }else{
            builderStore.recordsAsArray.forEach((record)=>{
                const builderSteps = [... getBuilderCompletedSteps(record as BuilderRecord.Model)];
                if(builderSteps.includes(stepName)){
                    buildersCompleted += 1;
                }
            });
        }


        if(buildersCompleted === builderListLen)
            return "completed";
        else if(buildersCompleted > 0)
            return "in-progress";
        else
            return "not-started";
    }

    const currentStep = computed(() => {
        let step = 0;
        let stepCount = 0;
        for(const check of checklistContent){
            if(!completedSteps.value.includes(check.description)){
                step = stepCount;
                break;
            }
            stepCount++;
        }
        return step;
    });
    const currentSubStep = computed(() => {
        const currentSubList = checklistContent[currentStep.value].subList;
        if(!currentSubList){
            return undefined;
        }
        let step = 0;
        let stepCount = 0;
        for(const subCheck of currentSubList){
            if(!completedSteps.value.includes(subCheck.description)){
                step = stepCount;
                break;
            }
            stepCount++;
        }
        return step;
    });

    //const totalProgress = computed(() => {
    //    let nonBuilders = 0;
    //    let subtractLenBy = 0;

    //    //const COMPLETEABLE_STEPS = 8 //Currently there are 9 total steps we are keeping track of progress on
    //    //const COMPLETEABLE_STEPS = 9; //Currently there are 10 total steps we are keeping track of progress on
    //    const COMPLETEABLE_STEPS = 11;

    //    const builderStore = useBuilderStore();

    //    let totalCompletableSteps = (COMPLETEABLE_STEPS * builderStore.asArray.length);


    //    totalCompletableSteps = totalCompletableSteps - subtractLenBy;
    //    //console.log("Total Completeable Steps", totalCompletableSteps);

    //    let totalCompletedSteps = 0;

    //    builderStore.recordsAsArray.forEach((record)=>{
    //        const builderCompletedSteps = [...getBuilderCompletedSteps(record as BuilderRecord.Model)].length;
    //        totalCompletedSteps += builderCompletedSteps;
    //    });


    //    return (totalCompletedSteps/totalCompletableSteps)*100;

    //});
    const totalProgress = computed(() => {
        // let nonBuilders = 0;
        // let subtractLenBy = 0;


        const COMPLETEABLE_STEPS = 12;

        const builderStore = useBuilderStore();


        let totalCompletableSteps = (COMPLETEABLE_STEPS * builderStore.selectableBuildersForPayments.length);
        // totalCompletableSteps = totalCompletableSteps - subtractLenBy;

        let totalCompletedSteps = 0;

        builderStore.selectableRecordsForPayment.forEach((record)=>{
            const builderCompletedSteps = [...getBuilderCompletedSteps(record as BuilderRecord.Model)].length;
            totalCompletedSteps += builderCompletedSteps;
        });

        return (totalCompletedSteps/totalCompletableSteps)*100;

    });


    return {checklistContent, availableSteps, completedSteps, currentStep, currentSubStep, totalProgress, allPoliciesCompleted,groupCompletedStep, getContactInfoStatus, setBuilderPaymentStatus, getBuilderCompletedSteps};
});