/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-depth */
/* eslint-disable max-lines-per-function */
import { BuilderRecord, Builders, DataInstance, Season } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useAuth } from "./auth";
import { useTrips } from "./trips";
import { useSeasons } from "./seasons";
import { DocumentEntity, SubsetEntity, dataInstanceToData } from "@busy-human/gearbox";
import { getDoc } from "firebase/firestore";

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };


export const useSeasonBuilders = defineStore('season-builders', () => {
    type LocalBuilder = WithRequired<Partial<Builders.Model>, 'name'>;

    const auth = useAuth();
    const season = useSeasons();

    
    const builders = ref<{[id: string]: LocalBuilder}>({});
    const builderSeasonRecords = ref<{[id: string]: {[seasonId: string]: BuilderRecord.Model}}>({});
    const authstate = ref<'waiting' | 'unauth' | 'ready'>('waiting');


    const builderSubset = ref<null | SubsetEntity<Builders.Model>>(null);
    const builderSeasonRecordEntities = ref<Record<string, DocumentEntity<BuilderRecord.Model>>>({});
    function setupListeners(uid: string) {
        const seasons = season.getSeasons();
        builderSubset.value = Builders.Collection.getBuildersForUserSubset(uid);
        builderSubset.value.onUpdate(() => {
            if(!builderSubset.value) return;
            for(const b of builderSubset.value.dataItems()) {
                if(!b.isDeleted) {
                    builders.value[b.$id] = dataInstanceToData(b);
                } else if(b.$id in builders.value) {
                    delete builders.value[b.$id];
                }

                if(!builderSeasonRecords.value?.[b.$id]) builderSeasonRecords.value[b.$id] = {} as {[seasonId: string]: BuilderRecord.Model};

                [...seasons.keys()].forEach((seasonId) => {
                    const builderSeasonId = `${b.$id}:${seasonId}`;
                    if(!(builderSeasonId in builderSeasonRecordEntities.value)) {
                        const ent = Season.Collection.subCollectionEntity(seasonId, "BuilderRecord")
                            .docEntity(b.$id);
                        ent.onUpdate(() => {
                            if(ent.data().season && ent.data().season === seasonId) builderSeasonRecords.value[b.$id][seasonId] = ent.data();
                        });
                        ent.listen();
                        builderSeasonRecordEntities.value[ent.$id] = ent;
                    }
                });
            }
            console.log("Listener Update");
        });
        builderSubset.value.listen();
    }
    function cleanupListeners() {
        for(const ent of Object.values(builderSeasonRecordEntities.value)) {
            ent.cleanup();
        }
        builderSeasonRecordEntities.value = {};
        builderSubset.value?.cleanup();
        builderSubset.value = null;
    }

    watch(() => [auth.currentUID], async ([uid]) => {
        if(uid) {
            setupListeners(uid as string);

            authstate.value = 'ready';
        } else {
            builders.value = {};
            builderSeasonRecords.value = {};
            cleanupListeners();

            authstate.value = 'unauth';
        }
    }, { immediate: true });

    const accountVisableSeasons = computed(() => {
        const seasons = new Set();
        Object.keys(builderSeasonRecords.value).forEach((seasonsToBuilder) => {
            Object.keys(builderSeasonRecords.value[seasonsToBuilder]).forEach((s) => {
                seasons.add(s);
            });
        });

        seasons.add(season.current?.$id);
        return [...seasons];
    });

    const accountSpansSeasons = computed(() => accountVisableSeasons.value.length > 1);


    return { builders, builderSeasonRecords, accountVisableSeasons, accountSpansSeasons};
});