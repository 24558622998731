<div class="builder-checklist"> <template v-for="check, idx in checklist" :key="idx"><div class="checklist-check" :class="{'selected': selectedRoute === check.pathName, 'checklist-status-selected': check.pathName === 'checklistStatus' &amp;&amp; selectedRoute === check.pathName, 'subcheck-selected': checkSubPath(selectedRoute) &amp;&amp; check.pathName === 'checklistStatus'}" @click="selectRoute(check.pathName)"><div class="checklist-main-content" :class="{'checklist-content': check.pathName === 'checklistStatus'}"><template v-if="check.pathName"><template v-if="pathToChecklistStatus[check.pathName] === 'complete'"><div class="checklist-index complete"><svg width="30" height="20" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38.3966 3.09703L34.9485 0.75328C33.9945 0.107245 32.6874 0.355144 32.0489 1.30166L15.1469 26.2265L7.37943 18.4591C6.56813 17.6478 5.24602 17.6478 4.43472 18.4591L1.4825 21.4113C0.671199 22.2226 0.671199 23.5447 1.4825 24.3636L13.4266 36.3077C14.0952 36.9762 15.1469 37.4871 16.0934 37.4871C17.0399 37.4871 17.9939 36.8936 18.6099 35.9997L38.9524 5.98916C39.5985 5.04264 39.3506 3.74306 38.3966 3.09703Z" fill="#5972B7"/>
</svg>
</div></template><template v-else-if="pathToChecklistStatus[check.pathName] === 'delayed'"><div class="checklist-index delayed"><svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0335 0C4.50069 0 0 4.22892 0 9.42761C0 14.6263 4.50069 18.8552 10.0335 18.8552C10.2595 18.8552 10.4803 18.838 10.7024 18.8245C10.4123 18.2451 10.2111 17.6215 10.1119 16.966C10.0844 16.966 10.0609 16.9697 10.0335 16.9697C5.60725 16.9697 2.00669 13.5866 2.00669 9.42761C2.00669 5.26866 5.60725 1.88552 10.0335 1.88552C14.4597 1.88552 18.0602 5.26866 18.0602 9.42761C18.0602 9.45339 18.0563 9.47548 18.0563 9.50126C18.754 9.59456 19.4176 9.78483 20.0343 10.0573C20.0486 9.84866 20.0669 9.63998 20.0669 9.42761C20.0669 4.22892 15.5662 0 10.0335 0ZM9.03012 3.77104V9.03725L5.31068 12.5321L6.72948 13.8652L11.0368 9.81797V3.77104H9.03012ZM17.0569 11.3131C14.2977 11.3131 12.0402 13.4343 12.0402 16.0269C12.0402 18.6195 14.2977 20.7407 17.0569 20.7407C19.8161 20.7407 22.0736 18.6195 22.0736 16.0269C22.0736 13.4343 19.8161 11.3131 17.0569 11.3131ZM16.3083 13.1987H17.8081L17.6409 16.9562H16.4729L16.3083 13.1987ZM17.0569 17.662C17.1614 17.662 17.2568 17.678 17.3404 17.7111C17.4266 17.7455 17.4985 17.7922 17.5586 17.8499C17.6174 17.9075 17.6631 17.9751 17.6957 18.0549C17.7284 18.1322 17.7454 18.2181 17.7454 18.3114C17.7441 18.4023 17.7284 18.4845 17.6957 18.5643C17.6631 18.6441 17.6174 18.7116 17.5586 18.7681C17.4985 18.8258 17.4266 18.8724 17.3404 18.9043C17.2568 18.9375 17.1614 18.9534 17.0569 18.9534C16.9511 18.9534 16.8583 18.9375 16.7734 18.9043C16.6898 18.8736 16.6166 18.8258 16.5565 18.7681C16.4977 18.7116 16.4507 18.6441 16.418 18.5643C16.3854 18.4845 16.3684 18.401 16.3684 18.3114C16.3684 18.2181 16.3854 18.1322 16.418 18.0549C16.4507 17.9751 16.4977 17.9075 16.5565 17.8499C16.6166 17.7922 16.6898 17.7455 16.7734 17.7111C16.8583 17.678 16.9524 17.662 17.0569 17.662Z" fill="#C9A45E"/>
</svg>
</div></template><template v-else-if="pathToChecklistStatus[check.pathName] === 'overdue'"><div class="checklist-index overdue"><div class="checkbox"></div></div></template><template v-else><div class="checklist-index"><div class="checkbox"></div></div></template></template><template v-else><div class="checklist-index"><div class="checkbox"></div></div></template><div class="checklist-content"> <div class="text">{{  check.description  }}</div><template v-if="check.pathName"><template v-if="pathToChecklistStatus[check.pathName] === 'overdue'"><div class="additional-info overdue">Overdue</div></template><template v-else-if="pathToChecklistStatus[check.pathName] === 'delayed'"><div class="additional-info delayed">You shouldn't do this yet</div></template></template></div></div><div class="chevy" v-if="check.subList" :class="{rotated: openDropdown === check.pathName}" @click.stop="handleDropdown(check.pathName)"><svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 15.6998L22 7.17481V0.299805L11 8.71481L0 0.299805V7.17481L11 15.6998Z" fill="#575757"/>
</svg>
</div></div><template v-if="openDropdown === check.pathName"><template v-if="check.subList"><template v-for="subCheck, subIdx in check.subList.filter((subCheck) =&gt; subCheck.value !== 'parent')" :key="subIdx"><template v-if="subCheck?.pathName"><div class="checklist-check sub-check" :class="{'selected': selectedRoute === subCheck.pathName}" @click="selectRoute(subCheck.pathName)"><div class="checklist-main-content"><template v-if="pathToChecklistStatus[subCheck.pathName] === 'complete'"><div class="checklist-index complete"><svg width="30" height="20" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38.3966 3.09703L34.9485 0.75328C33.9945 0.107245 32.6874 0.355144 32.0489 1.30166L15.1469 26.2265L7.37943 18.4591C6.56813 17.6478 5.24602 17.6478 4.43472 18.4591L1.4825 21.4113C0.671199 22.2226 0.671199 23.5447 1.4825 24.3636L13.4266 36.3077C14.0952 36.9762 15.1469 37.4871 16.0934 37.4871C17.0399 37.4871 17.9939 36.8936 18.6099 35.9997L38.9524 5.98916C39.5985 5.04264 39.3506 3.74306 38.3966 3.09703Z" fill="#5972B7"/>
</svg>
</div></template><template v-else-if="pathToChecklistStatus[subCheck.pathName] === 'delayed'"><div class="checklist-index delayed"><svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0335 0C4.50069 0 0 4.22892 0 9.42761C0 14.6263 4.50069 18.8552 10.0335 18.8552C10.2595 18.8552 10.4803 18.838 10.7024 18.8245C10.4123 18.2451 10.2111 17.6215 10.1119 16.966C10.0844 16.966 10.0609 16.9697 10.0335 16.9697C5.60725 16.9697 2.00669 13.5866 2.00669 9.42761C2.00669 5.26866 5.60725 1.88552 10.0335 1.88552C14.4597 1.88552 18.0602 5.26866 18.0602 9.42761C18.0602 9.45339 18.0563 9.47548 18.0563 9.50126C18.754 9.59456 19.4176 9.78483 20.0343 10.0573C20.0486 9.84866 20.0669 9.63998 20.0669 9.42761C20.0669 4.22892 15.5662 0 10.0335 0ZM9.03012 3.77104V9.03725L5.31068 12.5321L6.72948 13.8652L11.0368 9.81797V3.77104H9.03012ZM17.0569 11.3131C14.2977 11.3131 12.0402 13.4343 12.0402 16.0269C12.0402 18.6195 14.2977 20.7407 17.0569 20.7407C19.8161 20.7407 22.0736 18.6195 22.0736 16.0269C22.0736 13.4343 19.8161 11.3131 17.0569 11.3131ZM16.3083 13.1987H17.8081L17.6409 16.9562H16.4729L16.3083 13.1987ZM17.0569 17.662C17.1614 17.662 17.2568 17.678 17.3404 17.7111C17.4266 17.7455 17.4985 17.7922 17.5586 17.8499C17.6174 17.9075 17.6631 17.9751 17.6957 18.0549C17.7284 18.1322 17.7454 18.2181 17.7454 18.3114C17.7441 18.4023 17.7284 18.4845 17.6957 18.5643C17.6631 18.6441 17.6174 18.7116 17.5586 18.7681C17.4985 18.8258 17.4266 18.8724 17.3404 18.9043C17.2568 18.9375 17.1614 18.9534 17.0569 18.9534C16.9511 18.9534 16.8583 18.9375 16.7734 18.9043C16.6898 18.8736 16.6166 18.8258 16.5565 18.7681C16.4977 18.7116 16.4507 18.6441 16.418 18.5643C16.3854 18.4845 16.3684 18.401 16.3684 18.3114C16.3684 18.2181 16.3854 18.1322 16.418 18.0549C16.4507 17.9751 16.4977 17.9075 16.5565 17.8499C16.6166 17.7922 16.6898 17.7455 16.7734 17.7111C16.8583 17.678 16.9524 17.662 17.0569 17.662Z" fill="#C9A45E"/>
</svg>
</div></template><template v-else-if="pathToChecklistStatus[subCheck.pathName] === 'overdue'"><div class="checklist-index overdue"><div class="checkbox"></div></div></template><template v-else><div class="checklist-index"><div class="checkbox"></div></div></template><div class="checklist-content"> <div class="text">{{ subCheck.description }}</div><template v-if="pathToChecklistStatus[subCheck.pathName] === 'overdue'"><div class="additional-info overdue">Overdue</div></template><template v-else-if="pathToChecklistStatus[subCheck.pathName] === 'delayed'"><div class="additional-info delayed">You shouldn't do this yet</div></template></div></div></div></template></template><template v-if="check.subList.filter((subCheck) =&gt; subCheck.value === 'parent').length &gt; 0"><div class="checklist-section-header">Parent Builder Checklist			</div></template><template v-for="subCheck, subIdx in check.subList.filter((subCheck) =&gt; subCheck.value === 'parent')" :key="subIdx"><template v-if="subCheck?.pathName"><div class="checklist-check sub-check" :class="{'selected': selectedRoute === subCheck.pathName}" @click="selectRoute(subCheck.pathName)"><div class="checklist-main-content"><template v-if="pathToChecklistStatus[subCheck.pathName] === 'complete'"><div class="checklist-index complete"><svg width="30" height="20" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38.3966 3.09703L34.9485 0.75328C33.9945 0.107245 32.6874 0.355144 32.0489 1.30166L15.1469 26.2265L7.37943 18.4591C6.56813 17.6478 5.24602 17.6478 4.43472 18.4591L1.4825 21.4113C0.671199 22.2226 0.671199 23.5447 1.4825 24.3636L13.4266 36.3077C14.0952 36.9762 15.1469 37.4871 16.0934 37.4871C17.0399 37.4871 17.9939 36.8936 18.6099 35.9997L38.9524 5.98916C39.5985 5.04264 39.3506 3.74306 38.3966 3.09703Z" fill="#5972B7"/>
</svg>
</div></template><template v-else-if="pathToChecklistStatus[subCheck.pathName] === 'delayed'"><div class="checklist-index delayed"><svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0335 0C4.50069 0 0 4.22892 0 9.42761C0 14.6263 4.50069 18.8552 10.0335 18.8552C10.2595 18.8552 10.4803 18.838 10.7024 18.8245C10.4123 18.2451 10.2111 17.6215 10.1119 16.966C10.0844 16.966 10.0609 16.9697 10.0335 16.9697C5.60725 16.9697 2.00669 13.5866 2.00669 9.42761C2.00669 5.26866 5.60725 1.88552 10.0335 1.88552C14.4597 1.88552 18.0602 5.26866 18.0602 9.42761C18.0602 9.45339 18.0563 9.47548 18.0563 9.50126C18.754 9.59456 19.4176 9.78483 20.0343 10.0573C20.0486 9.84866 20.0669 9.63998 20.0669 9.42761C20.0669 4.22892 15.5662 0 10.0335 0ZM9.03012 3.77104V9.03725L5.31068 12.5321L6.72948 13.8652L11.0368 9.81797V3.77104H9.03012ZM17.0569 11.3131C14.2977 11.3131 12.0402 13.4343 12.0402 16.0269C12.0402 18.6195 14.2977 20.7407 17.0569 20.7407C19.8161 20.7407 22.0736 18.6195 22.0736 16.0269C22.0736 13.4343 19.8161 11.3131 17.0569 11.3131ZM16.3083 13.1987H17.8081L17.6409 16.9562H16.4729L16.3083 13.1987ZM17.0569 17.662C17.1614 17.662 17.2568 17.678 17.3404 17.7111C17.4266 17.7455 17.4985 17.7922 17.5586 17.8499C17.6174 17.9075 17.6631 17.9751 17.6957 18.0549C17.7284 18.1322 17.7454 18.2181 17.7454 18.3114C17.7441 18.4023 17.7284 18.4845 17.6957 18.5643C17.6631 18.6441 17.6174 18.7116 17.5586 18.7681C17.4985 18.8258 17.4266 18.8724 17.3404 18.9043C17.2568 18.9375 17.1614 18.9534 17.0569 18.9534C16.9511 18.9534 16.8583 18.9375 16.7734 18.9043C16.6898 18.8736 16.6166 18.8258 16.5565 18.7681C16.4977 18.7116 16.4507 18.6441 16.418 18.5643C16.3854 18.4845 16.3684 18.401 16.3684 18.3114C16.3684 18.2181 16.3854 18.1322 16.418 18.0549C16.4507 17.9751 16.4977 17.9075 16.5565 17.8499C16.6166 17.7922 16.6898 17.7455 16.7734 17.7111C16.8583 17.678 16.9524 17.662 17.0569 17.662Z" fill="#C9A45E"/>
</svg>
</div></template><template v-else-if="pathToChecklistStatus[subCheck.pathName] === 'overdue'"><div class="checklist-index overdue"><div class="checkbox"></div></div></template><template v-else><div class="checklist-index"><div class="checkbox"></div></div></template><div class="checklist-content"> <div class="text">{{ subCheck.description }}</div><template v-if="pathToChecklistStatus[subCheck.pathName] === 'overdue'"><div class="additional-info overdue">Overdue</div></template><template v-else-if="pathToChecklistStatus[subCheck.pathName] === 'delayed'"><div class="additional-info delayed">You shouldn't do this yet</div></template></div></div></div></template></template></template></template></template></div>