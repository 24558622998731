import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dotCount, (i) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createTextVNode(_toDisplayString('.'))
      ], 64))
    }), 256))
  ]))
}