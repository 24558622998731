/* eslint-disable complexity */
/* eslint-disable no-use-before-define */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-depth */
import { DocumentEntity, type DataInstance, type SubsetEntity } from "@busy-human/gearbox";
import { Builders, BuilderRecord, Season, BuilderDocuments } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { ref, computed,watch } from "vue";
import { useAuth } from "./auth";
import { useSeasons } from "./seasons";
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onSnapshot } from 'firebase/firestore';
import { storage } from '@/util/firebase';
import { useBuilderStore } from "./builder";
import { useSeasonFeature } from "@/composables/useSeasonFeature";

export type States = 'none' | 'uploading' | 'waiting' | 'submitting' | 'done' | 'error';

export const useDocumentStore = defineStore('documents', () => {
    const auth = useAuth();
    const season = useSeasons();
    const builders = useBuilderStore();
    const documentsEnabled = useSeasonFeature(Season.Features.DOCUMENTS);

    const builderDocuments = ref<{[builderId: string]: {[type: BuilderDocuments.DocumentType]: DataInstance<BuilderDocuments.Model>}}>({});
    const _cachedProfilePictures = ref<{[builderId: string]: string | null}>({});

    const waitingResolves: (() => void)[] = [];
    const isReady = ref(false);

    const builderSubset = ref<null | SubsetEntity<Builders.Model>>(null);
    const builderRecordDocuments = ref<Record<string, DocumentEntity<BuilderRecord.Model>>>({});
    function setupListeners(uid: string) {
        builderSubset.value = Builders.Collection.getBuildersForUserSubset(uid);
        builderSubset.value.onUpdate(() => {
            if(!builderSubset.value) return;
            for(const b of builderSubset.value.dataItems()) {
                const seasonId = season.current?.$id;
                if(!seasonId) return;
                if(!(b.$id in builderRecordDocuments.value)) {
                    if(!(b.$id in builderDocuments.value)){
                        builderDocuments.value[b.$id] = {};
                    }
                    const docs = Season.Collection.subCollectionEntity(seasonId, "BuilderRecord").subCollectionEntity(b.$id, "BuilderDocuments").subset();
                    
                    docs.onUpdate(() => {
                        for(const d of docs.dataItems()) {
                            builderDocuments.value[b.$id][d.$id] = d;
                        }
                    });
                    docs.listen();
                    builderRecordDocuments.value[b.$id] = docs;
                }
            }
        });
        builderSubset.value.listen();
    }

    function cleanupListeners() {
        for(const ent of Object.values(builderRecordDocuments.value)) {
            ent.cleanup();
        }
        builderRecordDocuments.value = {};
        builderSubset.value?.cleanup();
        builderSubset.value = null;
    }

    watch(() => auth.currentUID, async uid => {
        await season.waitForReady();

        if(uid) {
            await refreshData();
            setupListeners(uid);
        } else {
            builderDocuments.value = {};
            cleanupListeners();
        }
        isReady.value = true;
        waitingResolves.forEach(res => res());
        waitingResolves.length = 0;
    }, { immediate: true });


    const waitForReady = () => {
        if(isReady.value) {return Promise.resolve();}
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

    async function refreshData(seasonId?: string) {
        if(!auth.currentUID) return console.warn("Builder refresh called without being logged in");
        seasonId ??= season.current?.$id;
        if(!seasonId) throw new Error("Season not ready");
        const resolvedSeasonId = seasonId;
        const tmp: typeof builderDocuments.value = {};

        const docs = await Builders.Collection.getBuildersForUser(auth.currentUID);

        const builderDocs = await Promise.all(docs.filter(d => !d.isDeleted).map(async d =>{
            const documents = await Season.Collection.subCollectionEntity(resolvedSeasonId, "BuilderRecord").subCollectionEntity(d.$id, "BuilderDocuments").fetchAll();
            return {uid: d.$id, ...documents};
        })); 

        
        for(const builderDoc of builderDocs){
            tmp[builderDoc.uid] = {};
            builderDoc.items.forEach((item) => {
                tmp[builderDoc.uid][item.$id] = item.data();
            });
        }

        // eslint-disable-next-line require-atomic-updates
        builderDocuments.value = tmp;

        Object.keys(builderDocuments.value).forEach((builderId) => {
            if(builderDocuments.value[builderId]?.picture){
                getDocumentDownloadURL(builderId, 'picture');
            }
        });
    }

    watch(() => season.current?.$id, async (seasonId, old) => {
        if(seasonId && old && (seasonId !== old)) {
            await refreshData(seasonId);
        }
    });

    const uploadState = ref<States>('none');

    async function uploadUserDoc(builderId: string, docType: BuilderDocuments.DocumentType, file: File){
        if(!docType) return;
        const currentSeason = season.current?.$id;
        if(!currentSeason) return;

        if(documentsEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Document Uploading is disabled for this season');

        const uid = useAuth().currentUID;
        const newFileId = [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
        const ext = file.name.substring(file.name.lastIndexOf('.'));

        if(!uid || !builderId) return;

        const fileref = storageRef(storage, `user-documents/${currentSeason}/${uid}/${builderId}/${docType}/${newFileId}${ext}`)

        uploadState.value = 'uploading';
		console.log(`Uploading to ${fileref.fullPath}`);
		await uploadBytes(fileref, file);

        // Wait for the backend function to build the document
		uploadState.value = 'waiting';
		console.log("Waiting for backend...");

        const docEnt = Season.Collection.subCollectionEntity(currentSeason, "BuilderRecord").subCollectionEntity(builderId, "BuilderDocuments").docEntity(docType);
        const res =  await docEnt.listen();
        console.log("res:",res, docEnt.data());
        docEnt.cleanup();

        uploadState.value = 'none';
    }

    async function uploadHouseholdDoc(builderId: string, docType: BuilderDocuments.DocumentType, file: File){
        if(!docType) return;
        const currentSeason = season.current?.$id;
        if(!currentSeason) return;

        if(documentsEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Document Uploading is disabled for this season');
        
        const uid = useAuth().currentUID;
        const newFileId = [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
        const ext = file.name.substring(file.name.lastIndexOf('.'));

        if(!uid || !builderId) return;

        const fileref = storageRef(storage, `user-documents/${currentSeason}/${uid}/Household/${docType}/${newFileId}${ext}`);

        uploadState.value = 'uploading';
		console.log(`Uploading to ${fileref.fullPath}`);
		await uploadBytes(fileref, file);

        // Wait for the backend function to build the document
		uploadState.value = 'waiting';

        const docEnt = Season.Collection.subCollectionEntity(currentSeason, "BuilderRecord").subCollectionEntity(builderId, "BuilderDocuments").docEntity(docType);

        const res =  await docEnt.listen();
        console.log("res:",res, docEnt.data());
        docEnt.cleanup();

        uploadState.value = 'none';
    }

    async function getDocumentDownloadURL(builderId: string, documentType: BuilderDocuments.DocumentType){
        const doc = builderDocuments.value?.[builderId]?.[documentType];
        if(!doc) return null;

        const fileRef = storageRef(storage, doc.ref);

        const fileURL = await getDownloadURL(fileRef);
        // console.log("File url",fileURL);
        if(documentType === 'picture'){
            _cachedProfilePictures.value[builderId] = fileURL;
        }

        return fileURL;
    }

    async function getUserHealthFormDownloadURL(builderId: string){
        const builder = builders.builders[builderId];
        const builderRecord = builders.builderRecords[builderId];

        const healthFormRef = `user-documents/${builderRecord.season}/${builder.userId}/${builderId}/health-form-incomplete.pdf`;

        const fileRef = storageRef(storage, healthFormRef);

        const fileURL = await getDownloadURL(fileRef);

        return fileURL;
    }

    //const userProfilePic = computed(() => (builders.currentBuilder?.$id ?  _cachedProfilePictures.value[builders.currentBuilder.$id] || null : null));
    const userProfilePic = computed(() => (builders.currentBuilder?.$id ?  _cachedProfilePictures.value[builders.currentBuilder.$id] || null : null));

    return {
        builderDocuments, 
        waitForReady, 
        
        uploadState,
        uploadUserDoc,
        uploadHouseholdDoc,

        getDocumentDownloadURL,
        getUserHealthFormDownloadURL,

        userProfilePic
    };
});