/* eslint-disable max-lines-per-function */
import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { BuilderRecord, Builders, Season, type DataInstance } from '@busy-human/hxp-library';
import { useShoppingBuilderStore } from "./builder-shopping";
import { useAuth } from "./auth";
import { useSeasons } from "./seasons";
import { dataInstanceToData } from "@busy-human/gearbox";
import { submitCart as submitCartFunc } from "@/util/functions";
import { useBuilderStore } from "./builder";
import { analytics  } from "../../src/util/firebase";
import { logEvent } from "firebase/analytics";
import { getDoc } from "firebase/firestore";
import { useTrips } from "./trips";

export const useCartStore = defineStore('cart', () => {
    const LS_KEY = "hxp.carts";

    const lsres = localStorage.getItem(LS_KEY);
    const carts = ref<{[builderId: string]: DataInstance<BuilderRecord.TripPreference>[]}>({});

    const cartReady = ref<{[id: string]: boolean}>({});
    const auth = useAuth();
    const season = useSeasons();
    const trips = useTrips();

    async function loadLocalStorage(){
        if(lsres){
            const tmpCart = JSON.parse(lsres) as {[builderId: string]: DataInstance<BuilderRecord.TripPreference>[]};

            await trips.waitForReady();
            Object.keys(tmpCart).forEach(key => {
                tmpCart[key] = tmpCart[key].filter((pref: DataInstance<BuilderRecord.TripPreference>) => !!trips.trips[pref.$id]);
            });

            carts.value = {...carts.value, ...tmpCart};
        }
    }

    function getCartId(builderId: string): string
    function getCartId(builderId?: never): string | null
    function getCartId(builderId: string | undefined): string | null {
        const builderStore = useShoppingBuilderStore();

        const id = builderId || builderStore.selectedBuilderId;
        if(!id) return null;
        if(!builderStore.builders[id]) return null;

        if(Builders.IsBuilderAdult(builderStore.builders[id] as Builders.Model)){
            const typeDetails = builderStore.builderRecords[id]?.builderTypeDetails;
            if(typeDetails && typeDetails.goingOnTrip && typeDetails.goingAsPair && typeDetails.pairUser){
                return typeDetails.pairUser;
            }
        }

        return id;
    }

    const items = computed(() => {
        // const builderStore = useShoppingBuilderStore();
        // if(!builderStore.selectedBuilderId) return [];
        // const tmp = carts.value[builderStore.selectedBuilderId];
        // if(tmp) return tmp;
        // else return [];

        const cartId = getCartId();
        if(!cartId) return [];
        const tmp = carts.value[cartId];
        if(tmp) return tmp;
        else return [];
    });

    const itemsInCarts = computed(()=>{
        let length = 0;
        const builderStore = useShoppingBuilderStore();
        // console.log("Carts Value", carts.value);
        // console.log("Builders", builderStore.builders);
        // console.log("Builder Records", builderStore.builderRecords);

        for(let [key,value] of Object.entries(carts.value)){
            if(!builderStore.builders[key] || !builderStore.builderRecords[key]) continue;
            if(builderStore.builders[key].builderType === 'parentBuilder' && builderStore.builderRecords[key].builderTypeDetails.pairUser) continue;
            length += value.length;
        }
        return length;
    });

    function isSlotAdded(slotId: string) {
        return !!items.value.find(preference => preference.$id === slotId);
    }

    function addSlot(slotId: string, waitlist?: boolean) {
        if(season.overrideSet) return;
        const cartId = getCartId();
        const builderStore = useShoppingBuilderStore();

        const builderGroup = builderStore.getGroupBuilderGroup();

        if(cartId && builderGroup) {
            if(!carts.value[cartId])
                {carts.value[cartId] = [];}

            // if(carts.value[cartId].length < 20){

                carts.value[cartId].push({
                    $id: slotId,
                    builderGroupType: builderGroup,
                    isWaitlist: !!waitlist
                });
                if(carts.value[cartId].length === 1){
                    console.log("Tutorial complete");
                    logEvent(analytics,"tutorial_complete",{
                        slotId: slotId,
                        builderGroupType: builderGroup,
                        isWaitlist: !!waitlist,
                        builderId: builderStore.selectedBuilderId
                    });
                }else{
                    console.log("Add to cart");
                    logEvent(analytics, "add_to_cart",{
                        slotId: slotId,
                        builderGroupType: builderGroup,
                        isWaitlist: !!waitlist,
                        builderId: builderStore.selectedBuilderId
                    });
                    
                }
                cartReady.value[cartId] = false;
        }

        if(auth.isAuthenticated) return savePreferences();
    }

    function reorderSlots(slots: typeof carts.value[string], cartId = getCartId()) {
        if(cartId) {
            carts.value[cartId] = slots;
            if(auth.isAuthenticated) return savePreferences();
        }
    }

    function clearCart(cartId = getCartId()) {
        if(cartId) {
            carts.value[cartId] = [];
            cartReady.value[cartId] = false;
            if(auth.isAuthenticated) return savePreferences();
        }
    }

    async function savePreferences(builderId?: string) {
        if(!auth.isAuthenticated) throw new Error("Not logged in");

        const builderStore = useShoppingBuilderStore();
        const id = builderId || builderStore.selectedBuilderId;

        if(!id) throw new Error('Unable to resolve builder id');

        const builder = builderStore.builders[id];

        if(!builder) throw new Error('Unable to resolve builder');


        await season.waitForReady();
        const seasonId = season.current?.$id;

        if(!seasonId) throw new Error("Not in season");

        const Collection = Season.Collection.subCollectionEntity(seasonId, 'BuilderRecord');

        const docRef = Collection.docEntity(id);
        const details: BuilderRecord.Model['preferenceDetail'] = {};
        const cartItems = carts.value[id] || [];
        for(const item of cartItems) {
            details[item.$id] = dataInstanceToData(item);
        }
        await docRef.update({
            preferences: cartItems.map(i => i.$id),
            preferenceDetail: details
        });

        const pairUser = builderStore.builderRecords[id].builderTypeDetails?.pairUser;
        if(pairUser) {
            await Collection.docEntity(pairUser)
                .update({
                    preferences: cartItems.map(i => i.$id),
                    preferenceDetail: details
                });
        }

        return { id, seasonId };
    }

    async function submitCart(builderId?: string) {
        if(season.overrideSet) return;
        const { id, seasonId } = await savePreferences(builderId);

        await submitCartFunc({builderId: id, seasonId});
        await useBuilderStore().refreshData();
    }

    async function getExistingRecordForSeason(id: string, seasonId: string):  Promise<DataInstance<BuilderRecord.Model> | undefined>{
        const docRef = Season.Collection.subCollectionEntity(seasonId, "BuilderRecord").docRef(id);
        const snap = await getDoc(docRef);
        if(snap.exists()){
            return {$id: id, ...snap.data()} as DataInstance<BuilderRecord.Model>;
        }else{
            return undefined;
        }
    };

    watch(carts, () => {
        if(!season.overrideSet) localStorage.setItem(LS_KEY, JSON.stringify(carts.value));
    }, { deep: true });

    const isReady = ref(false);
    watch(() => [auth.currentUID, season.current?.$id], async ([uid, seasonId]) => {
        // carts.value = {};
        if(!isReady.value) isReady.value = true;
        if(!seasonId) return;

        // await loadLocalStorage();

        // Pull cart data from db if we've got it
        if(uid) {
            await loadLocalStorage();
            const builders = await Builders.Collection.getBuildersForUser(uid);

            const records = (await Promise.all(builders.filter(d => !d.isDeleted).map(d => getExistingRecordForSeason(d.$id, seasonId)))).filter((rec) => !!rec) as DataInstance<BuilderRecord.Model>[];

            // const records = await Promise.all(builders.map(b => Collection.fetchData(b.$id)));
            for(const builder of records) {
                const data = builder;
                if(data.preferences && data.preferences.length > 0 && !(carts.value[builder.$id] && carts.value[builder.$id].length > 0)) {
                    carts.value[builder.$id] = data.preferences.map(p => ({
                        $id: p,
                        ... data.preferenceDetail[p]
                    }));
                }
            }
        }
        else{
            carts.value = {};
            await loadLocalStorage();
        }
    }, { immediate: true });

    return {items, isSlotAdded, addSlot, reorderSlots, submitCart, carts, clearCart, cartReady, getCartId, itemsInCarts};
});