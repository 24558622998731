import { ChecklistDefinitions } from "@busy-human/hxp-library";
import { checklistPrimaryNode, subChecklistNode } from "../Navbar/checklist-types";
import { FeatureFlags } from "@busy-human/vue-component-library";
FeatureFlags.setFlags(CONFIG.flags);

// NavOptions for household and current builder is not a parent builder
export const checklistBaseline: checklistPrimaryNode[] = [
	{description: "Builder Info", pathName:"builderInfo"},
	{description: "Checklist Status", pathName: "checklistStatus", subList:[]},
	{description: "Parent at Home Info", pathName:"parentAtHomeInfo"},
	{description: "Manage Your Trip", pathName:"tripManagement"},
	{description: "Household", pathName: "household"},
	{description: "Policies", pathName:"policies"},
];

if(FeatureFlags.isFeatureEnabled("CHECKLIST_PAYMENTS")) {
	checklistBaseline.unshift({description: "Pay For Your Trip", pathName:"tripPayment"});
}
checklistBaseline.unshift({description: "Trip Details", pathName:"dashboard"});

export const taskIdToPath: Record<ChecklistDefinitions.BuiltInTaskId, string> = {
	'passport-submitted': 'passport',
	"builder-packet": "youthBuilderPacket",
	"parent-builder-packet": "parentBuilderPacket",
	"at-home-packet": "atHomeParentPacket",
	"parent-info-completed": "parentInfo",
	"emergency-contacts-submitted": "emergencyContacts",
	"all-about-you-submitted": "allAboutYou",
	"notarized-authorization-submitted": "notarizedAuthorization",
	"insurance-card-submitted": "healthForm",
	"immunization-record-submitted": "healthForm",
	"health-form-submitted": "healthForm",
	"group-me-joined": "groupMe",
	"airport-transportation-submitted": "flightInformation",
	"vaccination-submitted": "vaccines",
	"visa-submitted": "visa",
	"affidavit-submitted": "visa",
	"prep-videos-watched": "builderPrepVideos",
	"parent-training": "parentTraining",
	"parent-background-check": "parentBackgroundCheck"
};

export const categoryToPath: Record<ChecklistDefinitions.ChecklistCategory, string> = {
	'passport': 'passport',
	"builderPacket": "youthBuilderPacket",
	"parentBuilderPacket": "parentBuilderPacket",
	"atHomePacket": "atHomeParentPacket",
	"parentInfo": "parentInfo",
	"emergency": "emergencyContacts",
	"allAboutYou": "allAboutYou",
	"notarizedAuthorizations": "notarizedAuthorization",
	"healthForm": "healthForm",
	"groupMe": "groupMe",
	"flight": "flightInformation",
	"vaccination": "vaccines",
	"visa": "visa",
	"builderPrepVideos": "builderPrepVideos",
	"parentBuilderInfo": "parentBuilderInfo",
	"parentBackgroundCheck": "parentTraining",
	"parentTraining": "parentBackgroundCheck",
	"payment": "",
	"other": ""
};

export const pathToTaskId: Record<string, ChecklistDefinitions.BuiltInTaskId> = {
	'passport': 'passport-submitted',
	"youthBuilderPacket": "builder-packet",
	"parentBuilderPacket": 'parent-builder-packet',
	"atHomeParentPacket": "at-home-packet",
	"parentInfo": "parent-info-completed",
	"emergencyContacts": "emergency-contacts-submitted",
	"allAboutYou": "all-about-you-submitted",
	"notarizedAuthorization": "notarized-authorization-submitted",
	"healthForm": "health-form-submitted",
	"groupMe": "group-me-joined",
	"flightInformation": "airport-transportation-submitted",
	"vaccines": "vaccination-submitted",
	"visa": "visa-submitted",
	"builderPrepVideos": "prep-videos-watched",

	"insurance": "insurance-card-submitted",
	"immunization": "immunization-record-submitted",
	"affidavit": "affidavit-submitted",

	"parentTraining": 'parent-training',
	"parentBackgroundCheck": 'parent-background-check',
};

export const pathToCategory: Record<string, ChecklistDefinitions.ChecklistCategory> = {
	'passport': ChecklistDefinitions.ChecklistCategory.passport,
	"youthBuilderPacket": ChecklistDefinitions.ChecklistCategory.builderPacket,
	"parentBuilderPacket": ChecklistDefinitions.ChecklistCategory.parentBuilderPacket,
	"atHomeParentPacket": ChecklistDefinitions.ChecklistCategory.atHomePacket,
	"parentInfo": ChecklistDefinitions.ChecklistCategory.parentInfo,
	"emergencyContacts": ChecklistDefinitions.ChecklistCategory.emergency,
	"allAboutYou": ChecklistDefinitions.ChecklistCategory.allAboutYou,
	"notarizedAuthorization": ChecklistDefinitions.ChecklistCategory.notarizedAuthorizations,
	"healthForm": ChecklistDefinitions.ChecklistCategory.healthForm,
	"groupMe": ChecklistDefinitions.ChecklistCategory.groupMe,
	"flightInformation": ChecklistDefinitions.ChecklistCategory.flight,
	"vaccines": ChecklistDefinitions.ChecklistCategory.vaccination,
	"visa": ChecklistDefinitions.ChecklistCategory.visa,
	"builderPrepVideos": ChecklistDefinitions.ChecklistCategory.builderPrepVideos,
	"parentBuilderInfo": ChecklistDefinitions.ChecklistCategory.parentBuilderInfo,
	"parentTraining": ChecklistDefinitions.ChecklistCategory.parentTraining,
	"parentBackgroundCheck": ChecklistDefinitions.ChecklistCategory.parentBackgroundCheck
};

//TODO Change to Category to path for pages like Parent Info
export const pageIdToPath: Record<string, subChecklistNode> = {
	'passport': 			{description: "Passport", pathName: "passport"},
	'youthBuilderPacket':	{description: "Builder Packet", pathName:"youthBuilderPacket"},
	'parentBuilderPacket':	{description: "Parent Builder Packet", pathName:"parentBuilderPacket"},
	'atHomeParentPacket':	{description: "Parent at Home Packet", pathName:"atHomeParentPacket"},
	'parentInfo':			{description: "Parent/Guardian Info", pathName:"parentInfo"},
	'emergencyContacts':	{description: "Emergency Contacts", pathName:"emergencyContacts"},
	'allAboutYou':			{description: "All About You", pathName:"allAboutYou"},
	'notarizedAuthorization':	{description: "Notarized Authorization", pathName:"notarizedAuthorization"},
	'healthForm':			{description: "Health Form", pathName: "healthForm"},
	'groupMe':				{description: "GroupMe", pathName:"groupMe"},
	'flightInformation':		{description: "Flight Information", pathName: "flightInformation"},
	'vaccines':				{description: "Vaccines", pathName:"vaccines"},
	'visa':					{description: "Visa", pathName:"visa"},
	'builderPrepVideos':	{description: "Builder Prep Videos", pathName:"builderPrepVideos"},
	'parentBuilderInfo':	{description: "Parent Builder Info", pathName:"parentBuilderInfo", value:'parent'},
	'parentTraining':		{description: "Training", pathName:"parentTraining", value:'parent'},
	'parentBackgroundCheck':{description: "Background Check", pathName:"parentBackgroundCheck", value:'parent'},
	'other':				{description: "Other", pathName:"other"},
};

export const subChecklistItems: string[] = [
	"passport",
	"youthBuilderPacket",
	"parentBuilderPacket",
	"atHomeParentPacket",
	"parentInfo",
	"emergencyContacts",
	"allAboutYou",
	"notarizedAuthorization",
	"healthForm",
	"groupMe",
	"flightInformation",
	"vaccines",
	"visa",
	"builderPrepVideos",
	"parentBuilderInfo",
	"parentTraining",
	"parentBackgroundCheck",
	"other"
];