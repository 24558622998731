import { DataInstance, Season, Trips } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useSeasons } from "./seasons";
import { ISOTimestamp } from "@busy-human/gearbox";
import moment from 'moment';


export const useTrips = defineStore('trips', () => {
    const _trips = ref<Record<string, DataInstance<Trips.Model>>>({});
    const trips = computed(() => _trips.value);
    const seasonStore = useSeasons();

    const loaded = ref(false);
    const waitingPromises = ref<(() => void)[]>([]);

    // we can fetch all the available trips here
    watch(() => seasonStore.current?.$id, async seasonId => {
        const currentSeason = seasonId;
        if(!currentSeason) {
            console.log("Season not loaded yet");
            return;
        }
        
        try {
            loaded.value = false;
            const slots = (await Season.Collection.subCollectionEntity(currentSeason, 'Trips')
                .fetchAll()).dataItems();
    
            const tmp: typeof _trips['value'] = {};
            for(const slot of slots) {
                tmp[slot.$id] = slot;
            }
    
            _trips.value = tmp;
    
            loaded.value = true;
            waitingPromises.value.forEach(res => res());
            waitingPromises.value.length = 0;
        } catch (e) {
            console.error(e);
        }
    }, {immediate: true});

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => {waitingPromises.value.push(res);});
        else return Promise.resolve();
    }

    function getCountdown(startDate: ISOTimestamp){
        const today = moment();
        const start = moment(startDate);
        const months = start.diff(today, 'months');
        today.add(months,'months');
        const days = start.diff(today, 'days');
        today.add(days,'days');
        const hours = start.diff(today, 'hours');
        const countdown = {
            months,
            days,
            hours
        };
        
        return countdown;
    }

    const asArray = computed(() => Object.keys(_trips.value).map(slotId => _trips.value[slotId]));


    return { trips, asArray, waitForReady, getCountdown, loaded };
});